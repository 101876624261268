import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NewsPage = () => (
  <Layout>
    <SEO title="News" />
    <h1>FROM THE STAR-LEDGER BY HOWARD BRANT</h1>
    <p>There's no question Lake Ontario offers some of the finest bass, steelhead, lake and brown trout as well as coho and chinook salmon fishing to be found anywhere in North America.  And Ruddy's Fishing Camp does offer fine accommodations and top-flight fishing guides since Ruddy's is currently operated by second and third generation members of the Ditch Family.</p>
    <p>IN RECENT YEARS, with the creation of Lake Ontario's fabulous trout and salmon fishery by N.Y. State's Department
    of Environmental Conservation, literally thousands of so-called outfitters and fishing guides have appeared.</p>
    <p>Now most are legitimate and offer reasonably good sport.  But unfortunately a number have appeared which are
    unscrupulous, more interested in garnering the monetary rewards generated by the trout and salmon fishery then
    satisfying their customers.</p>
    <p>But such is not the case at Ruddy's Fishing Camp at Henderson Harbor, Ruddy's Fishing Camp was created shortly
    after the turn of the century by Ruddy Ditch who,at the time, concentrated his efforts on smallmouth bass.
    </p>
    <p>Remember, man;y decades ago Lake Ontario was barren of trout and salmon.  Smallmouth bass and northern pike
    were the mainstay for sportsmen.  During those early days the area about Henderson Harbor northward to Cape
    Vincent was ranked as one of the foremost smallmouth bass fishing sites in the America's.  It still remains so today.</p>
    <p>Ruddy's son, Ronald, came on the scene at an early age and continued his father's tradition.  He has remained in
    business since and his prowess as a guide has become legendary.</p>
    <p>Today Ron Ditch still presides over the operation.  In addition his sons have become equally established as third
    generation fishing guides and Ruddy's Camp bristles with fishermen during the spring, summer and early fall months.</p>
    <p>THE DITCH FAMILY did not just appear on the scene when the trout and salmon fishery blossomed.  All were born
    and reared on the shores of Lake Ontario.  They know its whims and produce outstanding fishing.</p>
    <p>Ruddy also offers all marina facilities including boat rentals and the camp itself is situated on the shore of Lake
    Ontario.</p>
    <p>As such, anglers looking for a prime fishing site at Lake Ontario for trout and heavyweight salmon and a fine camp,
    then we recommend Ruddy's Fishing Camp.</p>
  </Layout>
)

export default NewsPage
